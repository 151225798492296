import { Button } from 'components/shared/button';
import React from 'react';

import styles from './hero.module.scss';
import Background from './images/background-image-2xl.svg';
import BackgroundMd from './images/background-image-md.svg';
import BackgroundSm from './images/background-image-sm.svg';

const HeroView = () => (
  <section className={`container safe-paddings ${styles.relative}`}>
    <img
      className={styles.backgroundIllustration}
      src={Background}
      width={1214}
      height={480}
      loading="eager"
      alt=""
      aria-hidden
    />
    <img
      className={styles.backgroundIllustrationMd}
      src={BackgroundMd}
      width={768}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />
    <img
      className={styles.backgroundIllustrationSm}
      src={BackgroundSm}
      width={414}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />

    <div className={styles.wrapper}>
      <h1 className={styles.title}>Fault Injection Testing with k6</h1>
      <p className={styles.description}>Build fault tolerant Kubernetes applications</p>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          tag="a"
          size="sm"
          href="https://grafana.com/docs/k6/latest/testing-guides/injecting-faults-with-xk6-disruptor/"
        >
          XK6-disruptor docs
        </Button>
        <Button
          className={styles.button2}
          tag="a"
          size="sm"
          href="https://killercoda.com/grafana-xk6-disruptor/scenario/killercoda"
          theme="accent-primary"
        >
          Interactive demo
        </Button>
      </div>
    </div>
  </section>
);

export default HeroView;
