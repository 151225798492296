import CustomerQuote from 'components/shared/customer-quote/customer-quote';
import React from 'react';

import AlmudenaPhoto from './images/almudena-vivanco.jpg';
import SahaniPhoto from './images/sahani-perera.jpg';
import styles from './quotes.module.scss';

const Quotes = () => (
  <section className={'container'}>
    <div className={styles.inner}>
      <h2 className={styles.title}>What our users say</h2>
      <div className="row">
        <CustomerQuote
          classNames="col-lg-7 col-md-12 col-sm-12"
          key="feedbacks_1"
          color={false}
          name="Almudena Vivanco"
          avatar={AlmudenaPhoto}
          text={
            // eslint-disable-next-line max-len
            'Errors that I used to introduce through mocks and Traffic Controller, I can now do it within the same test using the k6 disruptor, which allows me to have all the test configurations centralized. There are still features missing, but most of the time, we need HTTP faults when running performance tests. The disruptor fits perfectly when you want to introduce delays or 5xx errors to test a circuit breaker or a retry policy.'
          }
          userinfo="Principal Performance Engineer at SCRM Lidl"
        />
        <CustomerQuote
          classNames="col-lg-5 col-md-12 col-sm-12"
          key="feedbacks_2"
          color="gray"
          name="Sahani Perera"
          avatar={SahaniPhoto}
          text={
            // eslint-disable-next-line max-len
            'Fault injection testing has become much easier with xk6-disruptor. By only using this xk6 extension, we can now inject faults into our Kubernetes systems and recreate application errors easily without any hassle.'
          }
          userinfo="Senior Quality Engineer at Sysco LABS"
        />
      </div>
    </div>
  </section>
);

export default Quotes;
