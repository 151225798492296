/* eslint-disable max-len */
import { SEO } from 'components/blocks/seo';
import CtaView from 'components/pages/fault-injection-testing/cta';
import Features from 'components/pages/fault-injection-testing/features';
import BrowserTestsIcon from 'components/pages/fault-injection-testing/features/images/e2e-browser-tests.inline.svg';
import InfrastructureTestsIcon from 'components/pages/fault-injection-testing/features/images/infrastructure-tests.inline.svg';
import LoadTestsIcon from 'components/pages/fault-injection-testing/features/images/load-tests.inline.svg';
import NetworkIcon from 'components/pages/fault-injection-testing/features/images/network.inline.svg';
import PatternsIcon from 'components/pages/fault-injection-testing/features/images/patterns.inline.svg';
import ToleranceIcon from 'components/pages/fault-injection-testing/features/images/tolerance.inline.svg';
import HeroView from 'components/pages/fault-injection-testing/hero';
import InjectionTestingView from 'components/pages/fault-injection-testing/injection-testing';
import Quotes from 'components/pages/fault-injection-testing/quotes';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const FEATURES_1_TITLE = 'Fault Injection to Build Resilient Distributed Systems';
const FEATURES_1_DESCRIPTION =
  "Failures are inevitable. Proactively test for (un)expected errors to improve the UX and system's architecture.";
const FEATURES_1 = [
  {
    icon: ToleranceIcon,
    title: 'Test tolerance for internal failures',
    description: 'Build your fallback strategy to prevent the propagation of internal failures disrupting your SLOs.',
  },
  {
    icon: NetworkIcon,
    title: 'Test slow networks and delays',
    description:
      'Provide a good user experience in situations of poor network performance, such as on mobile networks or delayed responses.',
  },
  {
    icon: PatternsIcon,
    title: 'Test resilience patterns',
    description:
      'Verify the outcome of high-availability and resilient techniques such as backoff, timeouts, retries, degradations, etc.',
  },
];

const FEATURES_2_TITLE = 'Add Fault Injection to Your Reliability Testing Strategy';
const FEATURES_2_DESCRIPTION =
  'Test each layer of your application and infrastructure with k6. Combine fault injection with other tests to recreate incidents and improve reliability and resiliency.';
const FEATURES_2 = [
  {
    icon: LoadTestsIcon,
    title: 'Faults within Load Tests',
    description:
      'Prepare for errors by introducing common API failures as part of your existing stress, spike, and average-load tests.',
  },
  {
    icon: BrowserTestsIcon,
    title: 'Faults within E2E Browser Tests',
    description:
      'Validate the fallback logic presented to users by testing the browser experience when injecting errors, delays, and high traffic.',
  },
  {
    icon: InfrastructureTestsIcon,
    title: 'Faults within Infrastructure Tests',
    description:
      'Test resilience to failures and scalability when planning the architecture and capacity of your infrastructure components.',
  },
];

const FaultInjectionTestingPage = () => (
  <DefaultLayout>
    <HeroView />
    <InjectionTestingView />
    <Features title={FEATURES_1_TITLE} description={FEATURES_1_DESCRIPTION} items={FEATURES_1} withBorder />
    <Quotes />
    <Features title={FEATURES_2_TITLE} description={FEATURES_2_DESCRIPTION} items={FEATURES_2} />
    <CtaView />
  </DefaultLayout>
);

export default FaultInjectionTestingPage;

export const query = graphql`
  query {
    ogImage: file(absolutePath: { regex: "/fault-injection-testing/k6-disruptor-meta.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 95)
        resize(width: 1200, quality: 95) {
          src
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const pageMetaData = {
    data: {
      ...SEO_DATA['fault-injection-testing'].data,
      image: data.ogImage,
    },
  };

  return <SEO {...pageMetaData} />;
};
