/* eslint-disable max-len */
import classNames from 'classnames/bind';
import React from 'react';

import Illustration2 from './images/illustration-2.svg';
import Illustration3 from './images/illustration-3.svg';
import Illustration from './images/illustration.nosvgo.svg';
import styles from './injection-testing.module.scss';

const cx = classNames.bind(styles);

const items = [
  'A large majority of outages result from incorrect handling of non-fatal errors.',
  'Embrace and tackle errors to validate fallback logic at each layer of your infrastructure and application.',
];

const items2 = [
  'Tests as code and best-in-class developer experience help adoption across engineering teams.',
  'Define faults and reusable tests in a language that developers and QA engineers know.',
];

const items3 = [
  'Connect your k6 script to the K8s cluster and start testing under application and service failures.',
  'Do not require to deploy and maintain a fleet of agents or operators.',
];

const InjectionTestingView = () => (
  <section className="safe-paddings container">
    <div className={cx('wrapper')}>
      <div className={cx('col-lg-7 col-md-6 col-sm-12')}>
        <div className={cx('illustration-wrapper')}>
          <img
            className={cx('illustration')}
            src={Illustration}
            height={700}
            width={460}
            alt="illustration"
            loading="lazy"
          />
        </div>
      </div>
      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('content-wrapper')}>
          <h3 className={cx('content-title')}>Design for failure. Collaborate across teams to handle errors.</h3>
          <ul className={cx('items')}>
            {items.map((item, index) => (
              <li className={cx('item-wrapper')} key={index}>
                <p className={cx('item-description')}>{item}</p>
              </li>
            ))}
          </ul>
          <a className="link" href="https://k6.io/blog/fault-injection-testing-microservices-with-xk6-disruptor/">
            Testing application failures &gt;
          </a>
        </div>
      </div>
    </div>

    <div className={cx('wrapper-2')}>
      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('content-wrapper-2')}>
          <h3 className={cx('content-title')}>No new DSLs, no extra CLI tools. Use the k6 Javascript API.</h3>
          <ul className={cx('items')}>
            {items2.map((item, index) => (
              <li className={cx('item-wrapper')} key={index}>
                <p className={cx('item-description')}>{item}</p>
              </li>
            ))}
          </ul>
          <a className="link" href="https://grafana.com/docs/k6/latest/javascript-api/xk6-disruptor/">
            xk6-disruptor API &gt;
          </a>
        </div>
      </div>
      <div className={cx('col-lg-7 col-md-6 col-sm-12')}>
        <div className={cx('illustration-wrapper-2')}>
          <img
            className={cx('illustration')}
            src={Illustration2}
            height={700}
            width={460}
            alt="illustration"
            loading="lazy"
          />
        </div>
      </div>
    </div>

    <div className={cx('wrapper-3')}>
      <div className={cx('col-lg-7 col-md-6 col-sm-12')}>
        <div className={cx('illustration-wrapper')}>
          <img
            className={cx('illustration')}
            src={Illustration3}
            height={700}
            width={460}
            alt="illustration"
            loading="lazy"
          />
        </div>
      </div>
      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('content-wrapper-3')}>
          <h3 className={cx('content-title')}>Test without additional burden. Do not require other components.</h3>
          <ul className={cx('items')}>
            {items3.map((item, index) => (
              <li className={cx('item-wrapper')} key={index}>
                <p className={cx('item-description')}>{item}</p>
              </li>
            ))}
          </ul>
          <a
            className="link"
            href="https://grafana.com/docs/k6/latest/testing-guides/injecting-faults-with-xk6-disruptor/how-it-works/"
          >
            How it works &gt;
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default InjectionTestingView;
