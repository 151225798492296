import classNames from 'classnames/bind';
import React from 'react';

import styles from './features.module.scss';

const cx = classNames.bind(styles);

const Features = ({ title, description, items, withBorder }) => (
  <section className="container safe-paddings">
    <div className={cx('inner')}>
      <h2 className={cx('title')}>{title}</h2>
      <p className={cx('description')}>{description}</p>
      <ul className={cx('items', 'grid-gap-x')}>
        {items.map(({ icon: Icon, title, description }, index) => (
          <li
            className={classNames(cx('items-item-wrapper'), withBorder && cx('items-item-wrapper-bordered'))}
            key={index}
          >
            <div className={cx('items-item')}>
              <Icon className={cx('items-item-icon')} />
              <h3 className={cx('items-item-title')}>{title}</h3>
              {/* eslint-disable-next-line react/no-danger */}
              <p className={cx('items-item-description')} dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default Features;
