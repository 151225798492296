import { Button } from 'components/shared/button';
import React from 'react';

import styles from './cta.module.scss';
import BackgroundSm from './images/background-image-sm.svg';
import Background from './images/background-image.svg';

const Cta = () => (
  <section className={`container safe-paddings ${styles.relative}`}>
    <img
      className={styles.backgroundIllustration}
      src={Background}
      width={1223}
      height={592}
      loading="lazy"
      alt=""
      aria-hidden
    />
    <img
      className={styles.backgroundIllustrationSm}
      src={BackgroundSm}
      width={414}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Ready to try k6 disruptor?</h2>
      <Button
        className={styles.button}
        tag="a"
        href="https://grafana.com/docs/k6/latest/testing-guides/injecting-faults-with-xk6-disruptor/"
        size="sm"
      >
        Get started
      </Button>
    </div>
  </section>
);

export default Cta;
